/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
:root {
  /** ==================================================================== **/
  /** Create Custom CSS Variables from "sfra bootstrap override" variables **/
  /** ==================================================================== **/
  --color-white: #fff;
  --color-black: #000;
  --color-blue: #0070d2;
  --color-green: #008827;
  --color-primary: #00a1e0;
  --color-red: #bd0000;
  --color-success: var(--color-green);
  --color-danger: var(--color-red);
  --color-light-blue: #7ed0ee;
  --color-grey1: #f5f5f5;
  --color-grey2: #e4e4e4;
  --color-grey3: #cfcfcf;
  --color-grey4: #97999b;
  --color-grey5: #4c5055;
  --color-grey6: #444;
  --color-grey7: #222;
  --color-grey8: #333;
  --color-grey-transparent-1: rgba(0, 0, 0, 0.65);
  --color-grey-transparent-2: rgba(0, 0, 0, 0.25);
  --color-light-gray: var(--color-grey1);
  --color-slightly-darker-gray: var(--color-grey4);
  --color-dark-gray: var(--color-grey6);
  --color-darker-gray: var(--color-grey7);
  --color-horizontal-rule-grey: var(--color-grey3);
  --color-product-number-grey: var(--color-grey3);
  --color-horizontal-border-grey: var(--color-grey4);
  --color-menu-link: var(--color-grey6);
  --color-close-menu-bg: var(--color-grey2);
  --color-link-color: var(--color-dark-gray);
  --color-hr-border-color: var(--color-grey3);
  /** ==================================================================== **/
  /** Define skin named variables here                                    **/
  /** ==================================================================== **/
  --skin-background-color-1: var(--color-gray1);
  --skin-border-color-1: var(--color-white);
  --skin-main-text-color-1: var(--color-gray7);
  --skin-main-text-color-2: var(--color-gray7);
  --skin-menu-color-1: #444;
  --skin-menu-color-1-invert: var(--color-white);
  --skin-menu-color-2: #222;
  --skin-link-color-1: #444;
  --skin-link-color-2: var(--color-blue);
  --skin-primary-color-1: var(--color-primary);
  --skin-primary-color-2: var(--color-primary);
  --skin-primary-color-invert-1: var(--color-white);
  --skin-selectbox-background-color-1: var(--color-white);
  --skin-selectbox-text-color-1: var(--skin-main-text-color-1);
  --skin-banner-background-color-1: #444;
  --skin-banner-background-color-2: #222;
  --skin-banner-text-color-1: var(--color-white);
  --skin-heading-color-1: var(--color-primary);
  --skin-heading-color-1-invert: var(--color-white);
  --skin-price-1: var(--color-gray7);
  --skin-header-font: 'Dosis';
  --skin-body-font: var(--font-family-sans-serif); }

:root {
  /** ==================================================================== **/
  /** Create Custom CSS Variables from "sfra bootstrap override" variables **/
  /** ==================================================================== **/
  --color-white: #fff;
  --color-black: #000;
  --color-blue: #0070d2;
  --color-green: #008827;
  --color-orange: #fe5000;
  --color-dark-orange: #eb4800;
  --color-darker-orange: #d24200;
  --color-abbey: #4c5055;
  --color-dune: #2d2926;
  --color-primary: #fe5000;
  --color-secondary: #4c5055;
  --color-accent: #fff;
  --color-red: #bd0000;
  --color-success: var(--color-orange);
  --color-danger: var(--color-red);
  --color-light-blue: #7ed0ee;
  --color-light-green: #88c290;
  --color-grey1: #f5f5f5;
  --color-grey2: #e4e4e4;
  --color-grey3: #cfcfcf;
  --color-grey4: #97999b;
  --color-grey5: #4c5055;
  --color-grey6: #444;
  --color-grey7: #222;
  --color-grey8: #333;
  --color-grey-transparent-1: rgba(0, 0, 0, 0.65);
  --color-grey-transparent-2: rgba(0, 0, 0, 0.25);
  --color-light-gray: var(--color-grey1);
  --color-slightly-darker-gray: var(--color-grey3);
  --color-dark-gray: var(--color-grey4);
  --color-darker-gray: var(--color-grey5);
  --color-horizontal-rule-grey: var(--color-grey3);
  --color-product-number-grey: var(--color-grey3);
  --color-horizontal-border-grey: var(--color-grey4);
  --color-menu-link: var(--color-grey5);
  --color-close-menu-bg: var(--color-grey2);
  --color-link-color: var(--color-dark-gray);
  --color-hr-border-color: var(--color-grey3);
  --util-box-shadow: 0 0 7px 0 rgb(119 119 119 / 50%);
  --util-box-shadow-active: 0 0 7px 0 rgb(119 119 119);
  /** ==================================================================== **/
  /** Define skin named variables here                                    **/
  /** ==================================================================== **/
  --skin-font-family-1: Muli;
  --skin-font-family-2: HelveticaNeue;
  --skin-background-color-1: var(--color-white);
  --skin-border-color-1: var(--color-white);
  --skin-box-shadow: var(--util-box-shadow);
  --skin-box-shadow-active: var(--util-box-shadow-active);
  --skin-main-text-color-1: var(--color-abbey);
  --skin-main-text-color-2: var(--color-abbey);
  --skin-menu-color-1: #444;
  --skin-menu-color-1-invert: var(--color-white);
  --skin-menu-color-2: #222;
  --skin-link-color-1: #444;
  --skin-link-color-2: var(--color-darker-orange);
  --skin-primary-color: var(--color-primary);
  --skin-primary-color-1: var(--color-primary);
  --skin-primary-color-invert: var(--color-accent);
  --skin-primary-color-invert-1: var(--color-accent);
  --skin-secondary-color: var(--color-secondary);
  --skin-accent-color: var(--color-accent);
  --skin-selectbox-background-color-1: var(--color-white);
  --skin-selectbox-text-color-1: var(--skin-main-text-color-1);
  --skin-banner-background-color-1: var(--color-secondary);
  --skin-banner-background-color-2: var(--color-secondary);
  --skin-banner-text-color-1: var(--color-white);
  --skin-heading-color-1: var(--color-primary);
  --skin-heading-color-1-invert: var(--color-accent);
  --skin-price-1: var(--color-grey7);
  --skin-header-font: Muli;
  --skin-header-font-2: HelveticaNeue;
  --skin-body-font: HelveticaNeue;
  --skin-promo-color: var($primary);
  --skin-font-weight-light: 300;
  --skin-font-weight-normal: 400;
  --skin-font-weight-bold: 700;
  --skin-font-weight-extra-bold: 800;
  --skin-font-weight-black: 900; }

body,
.card,
.card-header,
.hero {
  background-color: var(--skin-background-color-1);
  font-family: var(--skin-body-font);
  color: var(--skin-main-text-color-1); }

.hero {
  border-bottom-color: var(--skin-background-color-1); }

.main-menu {
  background-color: var(--skin-banner-background-color-1); }

.banner-color {
  background-color: var(--skin-banner-background-color-1); }

.header-banner {
  background-color: var(--skin-banner-background-color-2);
  color: var(--skin-banner-text-color-1); }

.header-banner .close-button .close {
  background-color: var(--skin-primary-color-1); }

h1.header.page-title,
h1.header.page-title::before {
  background-color: var(--skin-heading-color-1);
  color: var(--skin-heading-color-1-invert); }

.product-tile {
  background-color: var(--skin-background-color-1);
  border: 10px solid var(--skin-border-color-1); }

.refinements ul li button {
  color: var(--skin-primary-color-1); }

.custom-select {
  background-color: var(--skin-selectbox-background-color-1);
  color: var(--skin-selectbox-text-color-1); }

a {
  color: var(--skin-primary-color-1); }

header ~ #maincontent .container a:not(.btn-primary, .btn-outline-primary) {
  color: var(--skin-primary-color-1); }

.price {
  color: var(--skin-price-1); }

.navbar,
.navbar .nav-item,
.navbar .nav-item a,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a,
.dropdown-menu,
.dropdown-menu a {
  color: var(--skin-menu-color-1); }
  @media (min-width: 769px) {
    .navbar,
    .navbar .nav-item,
    .navbar .nav-item a,
    .navbar .nav-item.dropdown,
    .navbar .nav-item.dropdown a,
    .dropdown-menu,
    .dropdown-menu a {
      color: var(--skin-menu-color-1-invert); } }

.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown .dropdown-menu.show {
  color: var(--skin-selectbox-text-color-1); }

.navbar .nav-item.dropdown .dropdown-menu.show {
  background-color: var(--skin-selectbox-background-color-1); }
  .navbar .nav-item.dropdown .dropdown-menu.show a,
  .navbar .nav-item.dropdown .dropdown-menu.show .nav-item {
    color: var(--skin-selectbox-text-color-1); }

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
  background-color: var(--skin-menu-color-1-invert);
  color: var(--skin-menu-color-1) !important; }

.btn-primary {
  background-color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color-1);
  color: var(--skin-primary-color-invert-1); }

.btn-primary:hover {
  color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color-1);
  background-color: var(--skin-primary-color-invert-1); }

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color);
  color: var(--skin-primary-color-invert-1);
  opacity: 0.5; }

.btn-outline-primary {
  color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color-1); }

.btn-outline-primary:hover {
  color: var(--skin-primary-color-invert-1);
  background-color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color-1); }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--skin-header-font), sans-serif; }

a {
  color: var(--skin-link-color-1); }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
:root {
  /** ==================================================================== **/
  /** Create Custom CSS Variables from "sfra bootstrap override" variables **/
  /** ==================================================================== **/
  --color-white: #fff;
  --color-black: #000;
  --color-blue: #0070d2;
  --color-green: #008827;
  --color-primary: #00a1e0;
  --color-red: #c00;
  --color-success: var(--color-green);
  --color-danger: var(--color-red);
  --color-light-blue: #7ed0ee;
  --color-grey1: #f9f9f9;
  --color-grey2: #eee;
  --color-grey3: #ccc;
  --color-grey4: #999;
  --color-grey5: #666;
  --color-grey6: #444;
  --color-grey7: #222;
  --color-grey8: #333;
  --color-grey-transparent-1: rgba(0, 0, 0, 0.65);
  --color-grey-transparent-2: rgba(0, 0, 0, 0.25);
  --color-light-gray: var(--color-grey1);
  --color-slightly-darker-gray: var(--color-grey4);
  --color-dark-gray: var(--color-grey6);
  --color-darker-gray: var(--color-grey7);
  --color-horizontal-rule-grey: var(--color-grey3);
  --color-product-number-grey: var(--color-grey3);
  --color-horizontal-border-grey: var(--color-grey4);
  --color-menu-link: var(--color-grey6);
  --color-close-menu-bg: var(--color-grey2);
  --color-link-color: var(--color-dark-gray);
  --color-hr-border-color: var(--color-grey3);
  /** ==================================================================== **/
  /** Define skin named variables here                                    **/
  /** ==================================================================== **/
  --skin-background-color-1: var(--color-gray1);
  --skin-border-color-1: var(--color-white);
  --skin-main-text-color-1: var(--color-gray7);
  --skin-main-text-color-2: var(--color-gray7);
  --skin-menu-color-1: #444;
  --skin-menu-color-1-invert: var(--color-white);
  --skin-menu-color-2: #222;
  --skin-link-color-1: #444;
  --skin-link-color-2: var(--color-blue);
  --skin-primary-color-1: var(--color-primary);
  --skin-primary-color-2: var(--color-primary);
  --skin-primary-color-invert-1: var(--color-white);
  --skin-selectbox-background-color-1: var(--color-white);
  --skin-selectbox-text-color-1: var(--skin-main-text-color-1);
  --skin-banner-background-color-1: #444;
  --skin-banner-background-color-2: #222;
  --skin-banner-text-color-1: var(--color-white);
  --skin-heading-color-1: var(--color-primary);
  --skin-heading-color-1-invert: var(--color-white);
  --skin-price-1: var(--color-gray7);
  --skin-header-font: 'Dosis';
  --skin-body-font: var(--font-family-sans-serif); }

:root {
  /** ==================================================================== **/
  /** Create Custom CSS Variables from "sfra bootstrap override" variables **/
  /** ==================================================================== **/
  --color-white: #fff;
  --color-black: #000;
  --color-blue: #0070d2;
  --color-green: #008827;
  --color-primary: #1782c5;
  --color-secondary: #484848;
  --color-accent: #249be5;
  --color-red: #c00;
  --color-success: var(--color-green);
  --color-danger: var(--color-red);
  --color-light-blue: #7ed0ee;
  --color-light-green: #88c290;
  --color-grey1: #f9f9f9;
  --color-grey2: #eee;
  --color-grey3: #ccc;
  --color-grey4: #999;
  --color-grey5: #666;
  --color-grey6: #444;
  --color-grey7: #222;
  --color-grey8: #333;
  --color-grey-transparent-1: rgba(0, 0, 0, 0.65);
  --color-grey-transparent-2: rgba(0, 0, 0, 0.25);
  --color-light-gray: var(--color-grey1);
  --color-slightly-darker-gray: var(--color-grey4);
  --color-dark-gray: var(--color-grey6);
  --color-darker-gray: var(--color-grey7);
  --color-horizontal-rule-grey: var(--color-grey3);
  --color-product-number-grey: var(--color-grey3);
  --color-horizontal-border-grey: var(--color-grey4);
  --color-menu-link: var(--color-grey6);
  --color-close-menu-bg: var(--color-grey2);
  --color-link-color: var(--color-dark-gray);
  --color-hr-border-color: var(--color-grey3);
  --util-box-shadow: 0 0 7px 0 rgb(119 119 119 / 50%);
  --util-box-shadow-active: 0 0 7px 0 rgb(119 119 119);
  /** ==================================================================== **/
  /** Define skin named variables here                                    **/
  /** ==================================================================== **/
  --skin-background-color-1: var(--color-white);
  --skin-border-color-1: var(--color-white);
  --skin-box-shadow: var(--util-box-shadow);
  --skin-box-shadow-active: var(--util-box-shadow-active);
  --skin-main-text-color-1: var(--color-grey7);
  --skin-main-text-color-2: var(--color-grey7);
  --skin-menu-color-1: var(--color-white);
  --skin-menu-color-1-invert: var(--color-accent);
  --skin-menu-color-2: var(--color-secondary);
  --skin-link-color-1: var(--color-secondary);
  --skin-link-color-2: var(--color-primary);
  --skin-primary-color: var(--color-primary);
  --skin-primary-color-1: var(--color-primary);
  --skin-primary-color-invert-1: var(--color-accent);
  --skin-primary-color: var(--color-primary);
  --skin-primary-color-invert: var(--color-accent);
  --skin-secondary-color: var(--color-secondary);
  --skin-accent-color: var(--color-accent);
  --skin-selectbox-background-color-1: var(--color-white);
  --skin-selectbox-text-color-1: var(--skin-main-text-color-1);
  --skin-banner-background-color-1: var(--color-secondary);
  --skin-banner-background-color-2: var(--color-secondary);
  --skin-banner-text-color-1: var(--color-white);
  --skin-heading-color-1: var(--color-primary);
  --skin-heading-color-1-invert: var(--color-accent);
  --skin-price-1: var(--color-grey7);
  --skin-header-font: Source Sans Pro, sans-serif;
  --skin-body-font: Source Sans Pro, sans-serif;
  --skin-promo-color: var(--color-light-green);
  --skin-bg-accent: var(--color-grey2);
  --skin-bg-dark: var(--color-secondary); }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.highlight {
  background-color: var(--skin-bg-accent);
  padding: 2rem; }
  @media (min-width: 992px) {
    .highlight {
      padding: 3rem; } }

.product-tile {
  border-width: 0; }

.btn-primary {
  background-color: var(--skin-primary-color);
  border-color: var(--skin-primary-color);
  color: var(--color-white); }
  .btn-primary:hover {
    color: var(--color-white);
    border-color: var(--skin-primary-color-invert);
    background-color: var(--skin-primary-color-invert); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: var(--color-grey1);
    border-color: var(--skin-primary-color);
    color: var(--skin-primary-color);
    opacity: 0.5; }

.btn-outline-primary {
  background-color: var(--color-grey1);
  border-color: var(--skin-primary-color);
  color: var(--skin-primary-color); }
  .btn-outline-primary:hover {
    color: var(--color-white);
    border-color: var(--skin-primary-color-invert);
    background-color: var(--skin-primary-color-invert); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    opacity: 0.5; }
    .btn-outline-primary.disabled:hover, .btn-outline-primary:disabled:hover {
      background-color: var(--color-grey1);
      border-color: var(--skin-primary-color);
      color: var(--skin-primary-color); }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400; }

h1.header.page-title,
h1.header.page-title::before {
  background-color: transparent;
  color: var(--color-black); }
  .homepage h1.header.page-title, .homepage
  h1.header.page-title::before {
    background-color: var(--skin-heading-color-1);
    color: var(--skin-heading-color-1-invert); }

h2 {
  font-family: "Playfair Display", serif;
  font-weight: 400; }

h3 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 1.25rem; }
  @media (min-width: 769px) {
    h3 {
      font-size: 1.5rem; } }

a {
  color: var(--skin-link-color-1); }

.navbar,
.navbar .nav-item,
.navbar .nav-item a,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a,
.dropdown-menu,
.dropdown-menu a {
  color: var(--skin-menu-color-2); }
  @media (min-width: 769px) {
    .navbar,
    .navbar .nav-item,
    .navbar .nav-item a,
    .navbar .nav-item.dropdown,
    .navbar .nav-item.dropdown a,
    .dropdown-menu,
    .dropdown-menu a {
      color: var(--skin-menu-color-1); } }

header.accel .navbar-header {
  color: var(--color-white);
  background-color: var(--skin-secondary-color); }
  header.accel .navbar-header a {
    color: var(--color-white); }
  header.accel .navbar-header .user .popover {
    background-color: var(--color-grey2); }
    header.accel .navbar-header .user .popover a {
      color: var(--skin-secondary-color); }
  header.accel .navbar-header .country-selector .btn {
    color: var(--color-white); }
  header.accel .navbar-header .country-selector a {
    color: var(--skin-secondary-color); }
  header.accel .navbar-header .dropdown-country-selector {
    background-color: var(--color-grey2); }

header.accel .minicart {
  background-color: transparent; }
  @media (min-width: 992px) {
    header.accel .minicart {
      background-color: var(--color-primary); } }
  header.accel .minicart .minicart-quantity {
    color: var(--skin-secondary-color); }
    @media (min-width: 992px) {
      header.accel .minicart .minicart-quantity {
        color: var(--color-white); } }

@media (min-width: 992px) {
  header.accel .main-menu {
    background-color: transparent; } }

header.accel .header-main {
  background-color: var(--color-grey1); }

header.accel .navbar,
header.accel .navbar .nav-item,
header.accel .navbar .nav-item a,
header.accel .navbar .nav-item.dropdown,
header.accel .navbar .nav-item.dropdown a,
header.accel .dropdown-menu,
header.accel .dropdown-menu a {
  color: var(--skin-menu-color-2); }
  @media (min-width: 992px) {
    header.accel .navbar,
    header.accel .navbar .nav-item,
    header.accel .navbar .nav-item a,
    header.accel .navbar .nav-item.dropdown,
    header.accel .navbar .nav-item.dropdown a,
    header.accel .dropdown-menu,
    header.accel .dropdown-menu a {
      color: var(--skin-menu-color-2); } }

header.accel .nav-item .nav-link {
  background-color: transparent;
  color: var(--skin-secondary-color); }
  @media (min-width: 992px) {
    header.accel .nav-item .nav-link {
      border-bottom: 0.3125rem solid transparent;
      border-top: 0.3125rem solid transparent; } }

header.accel .nav-item .nav-link:hover,
header.accel .nav-item .nav-link:focus,
header.accel .nav-item.show .nav-link {
  background-color: transparent;
  color: var(--skin-menu-color-2) !important; }
  @media (min-width: 992px) {
    header.accel .nav-item .nav-link:hover,
    header.accel .nav-item .nav-link:focus,
    header.accel .nav-item.show .nav-link {
      border-bottom: 0.3125rem solid var(--skin-menu-color-1-invert); } }

@media (min-width: 992px) {
  header.accel .navbar-nav > .dropdown.mega-nav > .dropdown-menu > .dropdown-item > .dropdown-link {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--color-primary);
    font-size: 0.875rem; } }

footer {
  background-color: var(--skin-accent-color);
  color: var(--color-white); }
  footer h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: var(--color-white); }
    @media (min-width: 769px) {
      footer h2 {
        color: var(--skin-secondary-color); } }
  footer a {
    color: var(--color-white); }
  footer .store a:hover {
    color: var(--color-white); }
    @media (min-width: 769px) {
      footer .store a:hover {
        color: var(--skin-secondary-color); } }
  footer .container div.collapsible-xs button.title {
    color: var(--color-white); }
    @media (min-width: 769px) {
      footer .container div.collapsible-xs button.title {
        color: var(--skin-secondary-color); } }
  footer .back-to-top i.fa-circle {
    color: var(--color-grey1); }

.refinements .refinement {
  border: 0; }

.refinements ul li button {
  color: var(--skin-secondary-color); }

.refinements ul li.size-attribute button {
  border: 1px solid var(--color-grey5);
  background-color: var(--color-white); }
  .refinements ul li.size-attribute button.selected {
    border: 1px solid var(--skin-secondary-color);
    background-color: var(--skin-secondary-color);
    color: var(--color-white); }

@media (min-width: 769px) {
  .grid-tile-wrapper:hover {
    box-shadow: var(--skin-box-shadow);
    z-index: 2; } }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
:root {
  /** ==================================================================== **/
  /** Create Custom CSS Variables from "sfra bootstrap override" variables **/
  /** ==================================================================== **/
  --color-white: #fff;
  --color-black: #000;
  --color-blue: #0070d2;
  --color-green: #008827;
  --color-primary: #00a1e0;
  --color-red: #bd0000;
  --color-success: var(--color-green);
  --color-danger: var(--color-red);
  --color-light-blue: #7ed0ee;
  --color-grey1: #f5f5f5;
  --color-grey2: #e4e4e4;
  --color-grey3: #cfcfcf;
  --color-grey4: #97999b;
  --color-grey5: #4c5055;
  --color-grey6: #444;
  --color-grey7: #222;
  --color-grey8: #333;
  --color-grey-transparent-1: rgba(0, 0, 0, 0.65);
  --color-grey-transparent-2: rgba(0, 0, 0, 0.25);
  --color-light-gray: var(--color-grey1);
  --color-slightly-darker-gray: var(--color-grey4);
  --color-dark-gray: var(--color-grey6);
  --color-darker-gray: var(--color-grey7);
  --color-horizontal-rule-grey: var(--color-grey3);
  --color-product-number-grey: var(--color-grey3);
  --color-horizontal-border-grey: var(--color-grey4);
  --color-menu-link: var(--color-grey6);
  --color-close-menu-bg: var(--color-grey2);
  --color-link-color: var(--color-dark-gray);
  --color-hr-border-color: var(--color-grey3);
  /** ==================================================================== **/
  /** Define skin named variables here                                    **/
  /** ==================================================================== **/
  --skin-background-color-1: var(--color-gray1);
  --skin-border-color-1: var(--color-white);
  --skin-main-text-color-1: var(--color-gray7);
  --skin-main-text-color-2: var(--color-gray7);
  --skin-menu-color-1: #444;
  --skin-menu-color-1-invert: var(--color-white);
  --skin-menu-color-2: #222;
  --skin-link-color-1: #444;
  --skin-link-color-2: var(--color-blue);
  --skin-primary-color-1: var(--color-primary);
  --skin-primary-color-2: var(--color-primary);
  --skin-primary-color-invert-1: var(--color-white);
  --skin-selectbox-background-color-1: var(--color-white);
  --skin-selectbox-text-color-1: var(--skin-main-text-color-1);
  --skin-banner-background-color-1: #444;
  --skin-banner-background-color-2: #222;
  --skin-banner-text-color-1: var(--color-white);
  --skin-heading-color-1: var(--color-primary);
  --skin-heading-color-1-invert: var(--color-white);
  --skin-price-1: var(--color-gray7);
  --skin-header-font: 'Dosis';
  --skin-body-font: var(--font-family-sans-serif); }

:root {
  /** ==================================================================== **/
  /** Create Custom CSS Variables from "sfra bootstrap override" variables **/
  /** ==================================================================== **/
  --color-white: #fff;
  --color-black: #000;
  --color-blue: #0070d2;
  --color-green: #008827;
  --color-orange: #fe5000;
  --color-dark-orange: #eb4800;
  --color-darker-orange: #d24200;
  --color-abbey: #4c5055;
  --color-dune: #2d2926;
  --color-primary: #fe5000;
  --color-secondary: #4c5055;
  --color-accent: #fff;
  --color-red: #bd0000;
  --color-success: var(--color-orange);
  --color-danger: var(--color-red);
  --color-light-blue: #7ed0ee;
  --color-light-green: #88c290;
  --color-grey1: #f5f5f5;
  --color-grey2: #e4e4e4;
  --color-grey3: #cfcfcf;
  --color-grey4: #97999b;
  --color-grey5: #4c5055;
  --color-grey6: #444;
  --color-grey7: #222;
  --color-grey8: #333;
  --color-grey-transparent-1: rgba(0, 0, 0, 0.65);
  --color-grey-transparent-2: rgba(0, 0, 0, 0.25);
  --color-light-gray: var(--color-grey1);
  --color-slightly-darker-gray: var(--color-grey3);
  --color-dark-gray: var(--color-grey4);
  --color-darker-gray: var(--color-grey5);
  --color-horizontal-rule-grey: var(--color-grey3);
  --color-product-number-grey: var(--color-grey3);
  --color-horizontal-border-grey: var(--color-grey4);
  --color-menu-link: var(--color-grey5);
  --color-close-menu-bg: var(--color-grey2);
  --color-link-color: var(--color-dark-gray);
  --color-hr-border-color: var(--color-grey3);
  --util-box-shadow: 0 0 7px 0 rgb(119 119 119 / 50%);
  --util-box-shadow-active: 0 0 7px 0 rgb(119 119 119);
  /** ==================================================================== **/
  /** Define skin named variables here                                    **/
  /** ==================================================================== **/
  --skin-font-family-1: Muli;
  --skin-font-family-2: HelveticaNeue;
  --skin-background-color-1: var(--color-white);
  --skin-border-color-1: var(--color-white);
  --skin-box-shadow: var(--util-box-shadow);
  --skin-box-shadow-active: var(--util-box-shadow-active);
  --skin-main-text-color-1: var(--color-abbey);
  --skin-main-text-color-2: var(--color-abbey);
  --skin-menu-color-1: #444;
  --skin-menu-color-1-invert: var(--color-white);
  --skin-menu-color-2: #222;
  --skin-link-color-1: #444;
  --skin-link-color-2: var(--color-darker-orange);
  --skin-primary-color: var(--color-primary);
  --skin-primary-color-1: var(--color-primary);
  --skin-primary-color-invert: var(--color-accent);
  --skin-primary-color-invert-1: var(--color-accent);
  --skin-secondary-color: var(--color-secondary);
  --skin-accent-color: var(--color-accent);
  --skin-selectbox-background-color-1: var(--color-white);
  --skin-selectbox-text-color-1: var(--skin-main-text-color-1);
  --skin-banner-background-color-1: var(--color-secondary);
  --skin-banner-background-color-2: var(--color-secondary);
  --skin-banner-text-color-1: var(--color-white);
  --skin-heading-color-1: var(--color-primary);
  --skin-heading-color-1-invert: var(--color-accent);
  --skin-price-1: var(--color-grey7);
  --skin-header-font: Muli;
  --skin-header-font-2: HelveticaNeue;
  --skin-body-font: HelveticaNeue;
  --skin-promo-color: var($primary);
  --skin-font-weight-light: 300;
  --skin-font-weight-normal: 400;
  --skin-font-weight-bold: 700;
  --skin-font-weight-extra-bold: 800;
  --skin-font-weight-black: 900; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
h1 {
  font-family: "Muli";
  font-weight: 800;
  font-size: 2.5rem;
  line-height: 3rem;
  text-transform: uppercase; }

h2 {
  font-family: "Muli";
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-transform: uppercase; }

h3 {
  font-family: "HelveticaNeue";
  font-weight: 300;
  font-size: 1.75rem;
  line-height: 1.75rem; }

h4 {
  font-family: "HelveticaNeue";
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.1625rem; }

h5 {
  font-family: "Muli";
  font-weight: 800;
  font-size: 1rem;
  line-height: 1.15625rem;
  text-transform: uppercase; }

.page {
  background-color: var(--color-white); }

.page-heading {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem; }
  @media (min-width: 544px) {
    .page-heading {
      margin-top: 3.75rem;
      margin-bottom: 1.875rem; } }

.page-container {
  margin-bottom: 5rem; }
  .page-container.search-results {
    margin-bottom: 0; }

h1.page-title {
  margin: 0;
  color: var(--color-abbey); }

h1.header.page-title,
h1.header.page-title::before {
  background-color: transparent;
  color: var(--color-black); }
  .homepage h1.header.page-title, .homepage
  h1.header.page-title::before {
    background-color: var(--skin-heading-color-1);
    color: var(--skin-heading-color-1-invert); }

body {
  font-family: var(--skin-body-font);
  font-size: 1rem;
  line-height: 1.5rem; }

a {
  text-decoration: underline; }
  a:hover, a:active {
    text-decoration: none; }
  a:disabled {
    cursor: default; }
  header a,
  footer a {
    text-decoration: none; }

ul.stylized-list {
  list-style-type: none;
  padding-left: 0;
  font-family: "HelveticaNeue";
  font-weight: 300;
  font-size: 1.25rem;
  margin-bottom: 0; }
  @media (min-width: 544px) {
    ul.stylized-list {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2; } }
  ul.stylized-list li {
    position: relative;
    margin-bottom: 0.75rem;
    line-height: 1.75rem;
    padding-left: 1.4375rem; }
    @media (min-width: 544px) {
      ul.stylized-list li {
        width: 90%; } }
    ul.stylized-list li:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.4375rem;
      display: block;
      width: 0.8125rem;
      height: 0.8125rem;
      background-color: var(--color-white);
      border: 0.3125rem solid var(--skin-primary-color);
      border-radius: 50%; }

.btn {
  border-radius: 0.3125rem;
  border-width: 0.125rem;
  font-family: var(--skin-font-family-2);
  font-weight: var(--skin-font-weight-bold);
  font-size: 1.1875rem;
  line-height: 1.1875rem;
  padding: 0.75rem 1.25rem;
  text-decoration: none;
  text-transform: none; }
  .btn .fa {
    font-size: 89%; }

.btn-primary:not(:disabled):not(.disabled).focus, .btn-primary:not(:disabled):not(.disabled):focus, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled):hover {
  color: var(--color-white);
  border-color: var(--color-darker-orange);
  background-color: var(--color-darker-orange);
  box-shadow: none; }
  .btn-primary:not(:disabled):not(.disabled).focus.grey-button, .btn-primary:not(:disabled):not(.disabled):focus.grey-button, .btn-primary:not(:disabled):not(.disabled):active.grey-button, .btn-primary:not(:disabled):not(.disabled):hover.grey-button {
    background-color: #97999b;
    border-color: #97999b; }

.btn-primary.disabled, .btn-primary:disabled {
  background-color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color-1);
  color: var(--skin-primary-color-invert-1);
  opacity: 0.5;
  cursor: default; }
  .btn-primary.disabled.grey-button, .btn-primary:disabled.grey-button {
    background-color: #97999b;
    border-color: #97999b; }

.btn-no-bg {
  font-size: 1rem; }
  .btn-no-bg::after {
    content: '>>';
    font-size: 0.625rem;
    position: relative;
    top: -0.1875rem;
    letter-spacing: 0; }

.btn-back-to-top {
  border-radius: 1.875rem;
  padding-right: 40px;
  position: relative; }

.up_arrow {
  transform: rotate(-90deg);
  width: 20px;
  height: 20px;
  display: inline-block;
  font-size: 3.4375rem;
  position: absolute;
  right: 10px;
  font-weight: 300; }

.btn-outline-primary {
  background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled).focus, .btn-outline-primary:not(:disabled):not(.disabled):focus, .btn-outline-primary:not(:disabled):not(.disabled):hover, .btn-outline-primary:not(:disabled):not(.disabled):active {
    color: var(--color-darker-orange);
    border-color: var(--color-darker-orange);
    background-color: transparent;
    box-shadow: none; }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    cursor: default; }

::-moz-placeholder {
  color: var(--color-slightly-darker-gray); }

::placeholder {
  color: var(--color-slightly-darker-gray); }

:-ms-input-placeholder {
  color: var(--color-slightly-darker-gray); }

::-ms-input-placeholder {
  color: var(--color-slightly-darker-gray); }

.form-group {
  position: relative; }
  .form-group.quantity-form {
    margin-bottom: 0; }
  .form-group .info-icon {
    position: absolute;
    top: 2.5625rem;
    right: 0.5rem; }
    .form-group .info-icon .tooltip {
      background-color: var(--color-abbey);
      padding: 0.5rem 0.5625rem;
      font-size: 1rem;
      line-height: 1.15625rem;
      text-align: left;
      left: unset;
      right: -1.25rem;
      bottom: 2.375rem;
      transform: unset;
      z-index: 1; }
      .form-group .info-icon .tooltip::after {
        left: unset;
        right: 1.25rem; }
    .form-group .info-icon .icon {
      position: relative;
      color: transparent;
      width: 1.25rem;
      height: 1.25rem; }
      .form-group .info-icon .icon::before {
        display: inline;
        font-family: "Font Awesome 5 Free" !important;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 0.75rem;
        content: '\f129';
        color: var(--color-white);
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .form-group .info-icon ~ .form-control {
      padding-right: 2.5rem; }
    .form-group .info-icon:hover .icon {
      background-color: var(--color-dark-gray); }
  .form-group span {
    display: block;
    text-align: right; }
  .form-group.is-validated::after {
    display: block;
    font-family: "Font Awesome 5 Free" !important;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--color-dark-gray);
    background-color: var(--color-white);
    content: '\f058';
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    top: 2.40625rem;
    right: 0.5rem; }
  .form-group.saved-security-code .info-icon {
    right: 1.4375rem; }

.form-control-label {
  color: var(--color-black); }

.form-control {
  color: var(--color-black);
  border-color: var(--color-dark-gray);
  border-radius: 0.3125rem; }
  .form-control:focus {
    border-color: var(--color-dark-gray);
    box-shadow: 0 0 0 0.1875rem var(--color-slightly-darker-gray); }
  .form-control.is-invalid {
    border-width: 0.125rem; }
    .form-control.is-invalid ~ .invalid-feedback {
      font-size: 1rem;
      text-align: right; }
      .form-control.is-invalid ~ .invalid-feedback::after {
        display: block;
        font-family: "Font Awesome 5 Free" !important;
        font-size: 1.5rem;
        font-weight: 700;
        color: var(--color-red);
        background-color: var(--color-white);
        content: '\f057';
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        top: 2.40625rem;
        right: 0.5rem;
        pointer-events: none; }
        .card-number-wrapper .form-control.is-invalid ~ .invalid-feedback::after {
          top: 0.4375rem;
          right: 3.75rem; }
        .custom-select-group .form-control.is-invalid ~ .invalid-feedback::after {
          right: 1.5625rem; }
  .card-number-wrapper .form-control {
    padding-right: 5.625rem; }

.card-number-wrapper {
  background-color: transparent; }

.custom-select-group::after {
  display: block;
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 700;
  font-size: 1rem;
  content: '\f0d7';
  color: var(--color-abbey);
  position: absolute;
  height: 0.375rem;
  width: 0.625rem;
  pointer-events: none;
  z-index: 1;
  top: 2.375rem;
  right: 0.625rem; }

.custom-select-group.quantity-form::after {
  top: 1.75rem; }
  .line-item-quantity .custom-select-group.quantity-form::after {
    top: 2rem; }
  .minicart .custom-select-group.quantity-form::after {
    top: 1.75rem; }

.custom-select-group.sort-order-group::after {
  top: 0.4375rem;
  right: 1.875rem; }

.custom-select-group.no-label::after {
  top: 0.375rem; }

.custom-select {
  background: none;
  border-width: 0.125rem; }

.custom-control-label {
  -moz-user-select: none;
       user-select: none; }

.custom-checkbox .custom-control-label::before {
  border: 0.125rem solid var(--color-dune); }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  color: var(--color-white);
  font-family: "Font Awesome 5 Free" !important;
  content: '\f00c';
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.03125rem; }

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border: 0.125rem solid var(--color-dune);
  box-shadow: 0 0 0 0.1875rem var(--color-slightly-darker-gray); }

.custom-control-input:checked ~ .custom-control-label::before {
  border: 0.125rem solid var(--skin-primary-color-1); }

.custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.1875rem var(--color-slightly-darker-gray); }

.custom-radio .custom-control-label::before {
  border: 0.125rem solid var(--color-dune); }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border: 0.125rem solid var(--color-dune);
  background-color: var(--color-white); }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  display: inline-block;
  content: '';
  height: 0.5rem;
  width: 0.5rem;
  background-image: none;
  background-color: var(--color-primary);
  border-radius: 50%;
  position: absolute;
  top: 0.5rem;
  left: -1.25rem; }

@-moz-document url-prefix() {
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    margin-top: -0.0625rem; } }

.price .sales,
.price .range {
  font-family: "Muli";
  font-weight: 800;
  color: var(--skin-primary-color-1); }

.price.starting .starting-msg {
  display: block;
  font-family: "Muli";
  font-weight: 800;
  font-size: 0.625rem;
  line-height: 1.5rem;
  color: var(--color-abbey);
  text-transform: uppercase; }

.price.starting .starting-price {
  display: block;
  font-family: "Muli";
  font-weight: 800;
  color: var(--skin-primary-color-1); }

.price .strike-through {
  font-family: "Muli";
  font-weight: 800;
  color: var(--color-dark-gray); }

.modal-header {
  font-family: "Muli";
  font-weight: 800;
  font-size: 1rem;
  line-height: 1.15625rem;
  text-transform: uppercase;
  color: var(--color-abbey); }
  .modal-header .close:focus {
    outline: 0; }

.nav-tabs .nav-item .nav-link {
  text-decoration: none; }
  .nav-tabs .nav-item .nav-link.show, .nav-tabs .nav-item .nav-link:active, .nav-tabs .nav-item .nav-link:hover, .nav-tabs .nav-item .nav-link:focus {
    outline: 0.125rem solid var(--color-slightly-darker-gray);
    color: var(--color-darker-orange) !important; }

.valid-cookie-warning .close {
  height: 100%; }
  .valid-cookie-warning .close:focus, .valid-cookie-warning .close:hover {
    color: var(--color-white); }
  .valid-cookie-warning .close span {
    display: flex;
    height: 1rem;
    line-height: 1rem;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -0.25rem; }

footer {
  background-color: var(--color-abbey); }
  footer h2 {
    color: var(--color-white); }

.bg-img--none {
  background-image: none !important; }

.banner {
  margin: 1.875rem 0 3.125rem; }
  @media (min-width: 769px) {
    .banner {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-areas: "image caption";
      justify-items: stretch;
      align-items: stretch;
      margin: 0 auto;
      max-width: 1230px; }
      .banner.right {
        grid-template-areas: "caption image"; } }
  .banner .banner-image {
    grid-area: image;
    width: 100%;
    height: auto;
    margin: 0 0 0.9375rem; }
    @media (min-width: 769px) {
      .banner .banner-image {
        margin: 0; } }
    .banner .banner-image img {
      display: block;
      width: 100%;
      height: auto; }
      @media (min-width: 769px) {
        .banner .banner-image img {
          -o-object-fit: cover;
             object-fit: cover; } }
  .banner .banner-caption-container {
    grid-area: caption;
    margin: 0;
    padding: 0 1.875rem; }
    @media (min-width: 769px) {
      .banner .banner-caption-container {
        padding: 2.5rem;
        max-width: 24.375rem;
        align-self: center; } }
  .banner .banner-heading {
    font-family: "Muli";
    font-weight: 900;
    font-size: 1.875rem;
    line-height: 2.125rem;
    text-transform: uppercase;
    margin: 0 0 1.25rem; }
    @media (min-width: 769px) {
      .banner .banner-heading {
        font-size: 2.5rem;
        line-height: 2.875rem; } }
  .banner .banner-copy {
    font-family: "HelveticaNeue";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 0 1.25rem; }

.card-banner {
  margin: 1.875rem 0 2.5rem;
  padding: 0; }
  .card-banner.type-centered {
    margin: 3.125rem 0 3.75rem; }
  @media (min-width: 769px) {
    .card-banner {
      margin: 2.5rem auto;
      padding: 0 1.25rem;
      max-width: 1230px; }
      .card-banner.type-centered {
        margin: 4.375rem auto; } }
  .card-banner .banner-heading h3 {
    font-family: "Muli";
    font-weight: 800;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin: 0 0 1.25rem;
    text-align: center;
    text-transform: uppercase; }
    @media (min-width: 769px) {
      .card-banner .banner-heading h3 {
        font-size: 2.5rem;
        line-height: 2.875rem;
        margin: 0 0 2.5rem; } }
  .card-banner .card-tiles {
    display: grid;
    padding: 0 1.25rem; }
    @media (min-width: 769px) {
      .card-banner .card-tiles {
        grid-auto-flow: column;
        row-gap: unset; } }
  .card-banner.type-regular .card-tiles {
    grid-template-columns: 1fr 1fr;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
    row-gap: 1.25rem; }
    @media (min-width: 769px) {
      .card-banner.type-regular .card-tiles {
        grid-template-columns: unset;
        -moz-column-gap: 1.875rem;
             column-gap: 1.875rem; } }
  .card-banner.type-centered .card-tiles {
    row-gap: 3.125rem; }
    @media (min-width: 769px) {
      .card-banner.type-centered .card-tiles {
        -moz-column-gap: 1.875rem;
             column-gap: 1.875rem; } }
  .card-banner.type-card.video-results {
    display: none; }
  .card-banner.type-card .card-tiles {
    row-gap: 1.25rem; }
    @media (min-width: 769px) {
      .card-banner.type-card .card-tiles {
        -moz-column-gap: 2.125rem;
             column-gap: 2.125rem;
        justify-content: center; } }
    .card-banner.type-card .card-tiles.video-modules {
      display: flex;
      flex-wrap: wrap; }
  .card-banner.type-prod .card-tiles {
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem; }
    @media (min-width: 769px) {
      .card-banner.type-prod .card-tiles {
        flex-direction: row;
        justify-content: center;
        -moz-column-gap: 2.125rem;
             column-gap: 2.125rem; } }
    @media (min-width: 769px) {
      .card-banner.type-prod .card-tiles .card-banner-tile {
        max-width: 25%;
        width: 25%; } }
  .bg-accent .card-banner {
    padding: 2rem 0; }
    @media (min-width: 769px) {
      .bg-accent .card-banner {
        padding: 2rem 1.25rem; } }

.category-banner {
  margin: 1.875rem 0;
  padding: 0 1.25rem;
  width: 100%; }
  @media (min-width: 769px) {
    .category-banner {
      max-width: 1230px;
      margin: 3.125rem auto; } }
  .category-banner .banner-heading {
    margin: 0 0 1.5rem; }
    @media (min-width: 769px) {
      .category-banner .banner-heading {
        margin: 0 0 1.875rem; } }
    .category-banner .banner-heading h3 {
      color: var(--color-abbey);
      font-family: "HelveticaNeue";
      font-weight: 700;
      font-size: 1.75rem;
      line-height: 2rem;
      padding: 0 2.8125rem;
      text-align: center;
      text-transform: none; }
      @media (min-width: 769px) {
        .category-banner .banner-heading h3 {
          font-size: 2rem;
          line-height: 2.25rem;
          padding: 0; } }
  .category-banner .category-tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    @media (min-width: 769px) {
      .category-banner .category-tiles {
        flex-wrap: nowrap; } }

.hero-banner {
  margin: 0 0 1.875rem;
  width: 100%; }
  @media (min-width: 769px) {
    .hero-banner {
      position: relative;
      margin: 0 0 6.25rem;
      min-height: 35.625rem; } }
  .hero-banner .banner-image {
    width: 100%;
    height: auto;
    margin: 0 0 1.25rem;
    position: relative; }
    @media (min-width: 769px) {
      .hero-banner .banner-image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0; } }
    .hero-banner .banner-image::before {
      display: block;
      content: '';
      width: 100%;
      height: 25%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(0, transparent 0%, var(--color-abbey) 100%); }
      @media (min-width: 769px) {
        .hero-banner .banner-image::before {
          display: none; } }
    .hero-banner .banner-image img {
      display: block;
      width: 100%;
      height: auto; }
      @media (min-width: 769px) {
        .hero-banner .banner-image img {
          height: 100%;
          -o-object-fit: cover;
             object-fit: cover; } }
  @media (min-width: 769px) {
    .hero-banner .banner-caption-container {
      display: flex;
      justify-content: flex-start;
      position: absolute;
      left: 45.999%;
      bottom: -3.125rem;
      transform: translateX(-50%);
      width: 100%;
      max-width: 1230px;
      margin: 0;
      z-index: 1; } }
  @media (min-width: 769px) {
    .hero-banner.right .banner-caption-container {
      justify-content: flex-end; } }
  .hero-banner .banner-caption {
    padding: 0 0 0 1.25rem; }
    @media (min-width: 769px) {
      .hero-banner .banner-caption {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 40.625rem;
        padding: 0 6.25rem; }
        .hero-banner.right .banner-caption {
          align-items: flex-end; } }
  .hero-banner .banner-copy-container {
    position: relative;
    padding: 1.25rem 1.5625rem 1.5625rem; }
    @media (min-width: 769px) {
      .hero-banner .banner-copy-container {
        padding: 1.875rem;
        width: calc(100% - 100px); } }
    .hero-banner .banner-copy-container::after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: var(--color-black);
      opacity: 0.8; }
  .hero-banner .banner-heading {
    font-family: "Muli";
    font-weight: 900;
    font-size: 2.25rem;
    line-height: 2.5rem;
    text-transform: uppercase;
    margin: 0 0 1.25rem;
    padding: 0 1.25rem 0 0;
    color: var(--color-abbey);
    position: relative;
    z-index: 1; }
    @media (min-width: 769px) {
      .hero-banner .banner-heading {
        padding: 0;
        font-size: 3.25rem;
        line-height: 3.375rem;
        color: var(--color-white); }
        .hero-banner.right .banner-heading {
          text-align: right; } }
  .hero-banner .banner-copy {
    font-family: "HelveticaNeue";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 0 1.5625rem;
    color: var(--color-white);
    position: relative;
    z-index: 1; }
  .hero-banner .banner-cta {
    position: relative;
    z-index: 1; }

.image-banner {
  margin: 2.5rem 0 1.875rem;
  width: 100%; }
  @media (min-width: 769px) {
    .image-banner {
      position: relative;
      margin: 4.375rem 0 2.5rem; } }
  .image-banner .banner-image {
    width: 100%;
    height: auto; }
    @media (min-width: 769px) {
      .image-banner .banner-image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0; } }
    .image-banner .banner-image img {
      display: block;
      width: 100%;
      height: auto; }
      @media (min-width: 769px) {
        .image-banner .banner-image img {
          height: 100%;
          -o-object-fit: cover;
             object-fit: cover; } }
  .image-banner .banner-caption-container {
    display: flex;
    justify-content: flex-start;
    position: relative;
    max-width: 1230px;
    margin: 0 auto;
    z-index: 1; }
    @media (min-width: 769px) {
      .image-banner.right .banner-caption-container {
        justify-content: flex-end; } }
  .image-banner .banner-caption {
    background-color: var(--color-black);
    padding: 1.875rem 1.25rem; }
    @media (min-width: 769px) {
      .image-banner .banner-caption {
        background-color: transparent;
        position: relative;
        padding: 3.75rem 2.5rem 4.6875rem;
        max-width: 28.125rem; }
        .image-banner .banner-caption::after {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: var(--color-black);
          opacity: 0.8; } }
  .image-banner .banner-heading {
    font-family: "Muli";
    font-weight: 900;
    font-size: 1.875rem;
    line-height: 2.125rem;
    text-transform: uppercase;
    margin: 0 0 1.25rem;
    color: var(--color-white);
    position: relative;
    z-index: 1; }
    @media (min-width: 769px) {
      .image-banner .banner-heading {
        font-size: 2.5rem;
        line-height: 2.875rem; } }
  .image-banner .banner-copy {
    font-family: "HelveticaNeue";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 0 1.5625rem;
    color: var(--color-white);
    position: relative;
    z-index: 1; }
  .image-banner .banner-cta {
    position: relative;
    z-index: 1; }

.text-banner {
  margin: 1.25rem 0 1.875rem; }
  @media (min-width: 769px) {
    .text-banner {
      margin: 1.875rem 0 3.125rem; } }
  @media (min-width: 769px) {
    .text-banner .banner-caption-container {
      max-width: 1230px;
      margin: 0 auto; } }
  .text-banner .banner-heading {
    font-family: "Muli";
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 0.9375rem; }
    @media (min-width: 769px) {
      .text-banner .banner-heading {
        font-size: 1.75rem;
        line-height: 2.875rem; } }
  .text-banner .banner-copy {
    font-family: "HelveticaNeue";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    margin: 0 0 1.25rem; }
  .text-banner .banner-ctas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media (min-width: 769px) {
      .text-banner .banner-ctas {
        flex-direction: row; } }
    .text-banner .banner-ctas .banner-cta-secondary {
      margin: 1.25rem 0 0; }
      @media (min-width: 769px) {
        .text-banner .banner-ctas .banner-cta-secondary {
          margin: 0 0 0 1.5625rem; } }

.video-banner {
  margin: 1.875rem 0 1.25rem; }
  @media (min-width: 769px) {
    .video-banner {
      margin: 2.5rem auto 1.875rem;
      max-width: 1230px; } }
  .video-banner .banner-image {
    display: block;
    margin: 0 auto;
    padding: 0 1.25rem;
    width: 100%;
    height: auto;
    max-width: 47.625rem;
    position: relative; }
    .video-banner .banner-image::before {
      display: block;
      content: '';
      height: 7.3125rem;
      width: 7.3125rem;
      background-color: var(--color-black);
      opacity: 0.65;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1; }
      @media (min-width: 769px) {
        .video-banner .banner-image::before {
          height: 11.5625rem;
          width: 11.5625rem; } }
    .video-banner .banner-image::after {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3.125rem;
      width: 2.8125rem;
      font-family: "Font Awesome 5 Free" !important;
      font-weight: 700;
      font-size: 3.125rem;
      content: '\f04b';
      color: var(--color-primary);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      margin-left: 0.3125rem;
      -webkit-text-stroke: 0.3125rem var(--color-white); }
      @media (min-width: 769px) {
        .video-banner .banner-image::after {
          height: 4.0625rem;
          width: 5rem;
          font-size: 4.0625rem;
          margin-left: 0.5rem; } }
    .video-banner .banner-image picture {
      display: block;
      width: 100%;
      height: auto;
      padding: 0.375rem;
      box-shadow: 0 0 0.3125rem var(--color-slightly-darker-gray); }
      @media (min-width: 769px) {
        .video-banner .banner-image picture {
          padding: 0.9375rem; } }
    .video-banner .banner-image img {
      width: 100%;
      height: auto;
      -o-object-fit: cover;
         object-fit: cover; }
  .video-banner .banner-heading {
    font-family: "Muli";
    font-weight: 900;
    font-size: 1.875rem;
    line-height: 2.125rem;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 1.25rem;
    padding: 0 1.25rem; }
    @media (min-width: 769px) {
      .video-banner .banner-heading {
        font-size: 2.5rem;
        line-height: 2.875rem;
        margin: 0 0 1.5625rem; } }

.overflow-bottom-banner {
  background: var(--skin-primary-color);
  margin-bottom: 0.9375rem;
  padding-top: 0.9375rem; }
  .overflow-bottom-banner .container {
    position: relative; }
    @media (min-width: 769px) {
      .overflow-bottom-banner .container {
        position: initial; } }
  .overflow-bottom-banner .banner {
    margin: 0; }
    @media (min-width: 769px) {
      .overflow-bottom-banner .banner {
        position: relative;
        bottom: -0.9375rem; } }
    .overflow-bottom-banner .banner .banner-image {
      margin: 0; }
  @media (min-width: 769px) {
    .overflow-bottom-banner.video {
      position: relative;
      background: none; } }
  .overflow-bottom-banner.video .col-md-4 {
    order: 2; }
  @media (min-width: 769px) {
    .overflow-bottom-banner.video .col-md-8 {
      order: 2; } }
  @media (min-width: 769px) {
    .overflow-bottom-banner.video .video-banner {
      margin: 0; } }
  @media (min-width: 769px) {
    .overflow-bottom-banner.video .banner-image {
      padding: 0;
      margin: 0; } }
  .overflow-bottom-banner.video .banner-image picture {
    padding: 0;
    box-shadow: none; }
  .overflow-bottom-banner.video .container .row {
    position: relative;
    z-index: 2; }
  .overflow-bottom-banner.video .container::after {
    background: var(--skin-primary-color);
    display: block;
    content: '';
    height: 9%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    left: 0;
    right: 0; }
    @media (min-width: 769px) {
      .overflow-bottom-banner.video .container::after {
        height: 70%; } }
  .overflow-bottom-banner.video .banner-copy-container {
    background-color: var(--color-black);
    padding: 1.875rem;
    color: var(--color-white); }
    @media (min-width: 769px) {
      .overflow-bottom-banner.video .banner-copy-container {
        height: 100%; } }

@media screen and (min-width: 1025px) and (max-width: 1600px) {
  .page:has(.cat-landing) header {
    position: fixed;
    left: 0;
    right: 0; }
    .page:has(.cat-landing) header .header {
      padding: 0.5rem 1.25rem; }
    .page:has(.cat-landing) header .main-menu .navbar-nav > .nav-item {
      padding: 0.5rem 1.25rem; }
  .page:has(.cat-landing) #maincontent {
    margin-top: 120px; } }

.sticky-header .header-banner {
  overflow: hidden; }

.sticky-header #maincontent:has(.cat-landing) {
  margin-top: 120px; }

.cat-landing .banner-heading {
  color: var(--White, #FFF); }

.cat-landing .banner-copy-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
  padding-top: 24px; }

.cat-landing .banner-caption-container {
  top: calc(100% - 0px);
  /* Offset by the negative padding of the .banner-copy-container to make its top edge touch the bottom of the image */
  width: 100%; }

.cat-landing .banner-caption {
  position: relative;
  bottom: calc(100% - 0px); }

.cat-landing .hero-banner {
  margin-bottom: 0.125rem; }
  .cat-landing .hero-banner .bulb-finder-component {
    display: none; }
  .cat-landing .hero-banner .banner-image {
    margin: 0; }
    .cat-landing .hero-banner .banner-image img {
      height: 25svh;
      -o-object-fit: cover;
         object-fit: cover; }
  .cat-landing .hero-banner .banner-caption {
    padding: 0; }
  .cat-landing .hero-banner .banner-heading {
    position: absolute;
    bottom: 100%;
    text-transform: uppercase;
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
    margin-bottom: 0.625rem; }
  .cat-landing .hero-banner .banner-copy {
    color: var(--White, #FFF);
    font-size: 14px;
    line-height: 21px;
    margin: 0; }
    .cat-landing .hero-banner .banner-copy p {
      margin: 0; }
  .cat-landing .hero-banner .banner-cta {
    display: flex;
    padding: 13px 29px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--White, #FFF);
    font-family: "Muli";
    font-weight: 800;
    font-size: 16px;
    line-height: 20px; }

.cat-landing .card-banner {
  margin-top: 1.5rem; }
  .cat-landing .card-banner .banner-heading {
    color: var(--Gray, #4C5055);
    padding: 0 20px; }
    .cat-landing .card-banner .banner-heading h3 {
      font-size: 32px;
      font-weight: 900;
      line-height: 40px;
      /* 125% */
      text-align: left; }
  .cat-landing .card-banner .sub-heading {
    padding: 0 20px;
    font-size: 22px;
    line-height: 26px; }
  .cat-landing .card-banner .experience-commerce_assets-accordionTopicV2 h2 {
    color: var(--Orange, #FE5000);
    /* Mobile H2 */
    font-family: "Muli";
    font-weight: 800;
    font-size: 24px;
    font-style: normal;
    line-height: 29px;
    /* 120.833% */
    text-transform: uppercase; }

@media (min-width: 769px) {
  .cat-landing .banner-copy-container {
    top: unset; }
  .cat-landing .hero-banner {
    min-height: unset;
    margin-bottom: 120px; }
    .cat-landing .hero-banner .banner-caption-container {
      bottom: -95px;
      position: absolute;
      top: auto; }
    .cat-landing .hero-banner .banner-image {
      position: relative; }
      .cat-landing .hero-banner .banner-image img {
        height: auto;
        height: 40svh;
        width: 100%; }
    .cat-landing .hero-banner .banner-heading {
      margin-left: 0; }
    .cat-landing .hero-banner .banner-caption {
      padding: 0 6.25rem;
      max-width: 50rem; }
    .cat-landing .hero-banner .banner-copy-container {
      gap: 10px;
      padding: 20px;
      justify-content: center;
      width: calc(100% - 24px);
      max-width: 425px;
      padding: 30px; }
      .cat-landing .hero-banner .banner-copy-container .banner-copy {
        font-size: 16px; }
  .cat-landing .card-banner {
    margin-top: 2.5rem; }
    .cat-landing .card-banner .card-tiles {
      grid-auto-columns: 1fr; }
    .cat-landing .card-banner .banner-heading {
      display: flex; }
      .cat-landing .card-banner .banner-heading h3 {
        font-size: 52px;
        line-height: 65px;
        /* 125% */ }
    .cat-landing .card-banner .sub-heading {
      font-size: 26px; } }

@media screen and (min-width: 1025px) and (max-width: 1600px) {
  .cat-landing .hero-banner {
    margin-bottom: 110px; }
    .cat-landing .hero-banner .banner-image img {
      height: 38svh; }
  .cat-landing .card-banner {
    margin-top: 1rem; }
    .cat-landing .card-banner .sub-heading {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; } }

.installPageHeader {
  background: var(--color-primary);
  color: var(--color-white);
  padding-top: 1.5rem; }

#findvideoRedirect {
  padding: 0;
  margin-top: 1.25rem;
  height: 2.75rem; }
